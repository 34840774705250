import React, { useEffect, useState } from 'react';
import logo from './assets/gophr_logo.svg';
import './App.css';

function App() {

  var [counter, setCounter] = useState(5);

  function checkCounter() {
    if(counter === 0) {
      window.location.replace('https://www.gophrapp.com');
    }
    else {
      setTimeout(
        () => {
          setCounter(counter - 1);
        },
        1000
      )
    }
  }
  useEffect(() => {
    document.title = 'Gophr App'
    checkCounter();
  }, []);

  useEffect(() => {
    checkCounter();
  }, [counter])
  
  return (
    <div className="App" style={{background: "linear-gradient(to right, #F77E0B, #AE0721)"}}>
      <header className="App-header">
        <img src={logo} alt="logo" />
        <p style={{fontWeight: 'bold'}}>
          We Have Moved!
        </p>
        <p className="body">
          Our new website is &nbsp;
          <a
            style={{color: 'white', textDecoration: 'underline', fontWeight: 'bold', cursor: 'pointer'}}
            rel="noopener noreferrer"
            onClick={() => window.location.replace('https://www.gophrapp.com')}
          >
            www.gophrapp.com
          </a>
          .
        </p>
        <p className="body">
          Redirecting you there in {counter}.
        </p>
        
      </header>
    </div>
  );
}

export default App;
